// $primary: #204ecf;
// $secondary: #262d3d;

@import '~bootstrap/scss/bootstrap';
@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
.react-datepicker__month-container {
    // background-color: #081833;
    color: #969eac;
    font-size: 1.2rem;
    font-family: 'Mulish';

    .react-datepicker__month {
        padding: 1rem 0;
    }

    .react-datepicker__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;
        font-size: 1.5rem;
        padding: 0.2rem;

        &:hover {
            background-color: #534cea;
        }
    }


}
