/* 
SPACING SYSTEM (PX)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 /96 / 128 

FONT SIZE SYSTM (type-scale.com for corersponding rem units)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 /44 / 52 / 62 / 74 / 86 / 98
- Font weights Default: 400 
  Medium: 500 
  Semi-bold: 600 
  Bold: 700 
- Line heights 
  Default: 1 
  Small: 1.05 
  Medium: 1.2 
  Paragraph default: 1.6 Large: 1.8 
- Letter spacing -0.5px 0.75px


COLOR PCIKER SYSTEMS: MAIN / ACCENT / GREY (TINTS & SHADES)
Tailwindcss, palleton.com/coolors, Tint & Shade Generator

--- 05 SHADOWS
0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS
Default: 9px
Medium: 11px

--- 07 WHITESPACE
- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

Image Compression Tool: SQOOSH 
ICON WEBSITES: Phosphor incons, ionicons, icons8, heroicons.com
*/

/* style sheet for mortgage calculator */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* root variables (custom properties used throughout */
:root {
  /*
# hsl(221, 23%, 25%)
# hsl(220, 27%, 44%)
# hsl(207, 46%, 70%)
# hsl(218, 46%, 90%)
# hsl(213, 100%, 98%)
# hsl(218, 19%, 92%)
# hsl(208, 18%, 72%)
# hsl(210, 10%, 49%)
# hsl(210, 8%, 28%)
*/

  --gray10Color: hsl(206, 14%, 10%);
  --gray9Color: hsl(220, 24%, 25%);
  --gray8Color: hsl(204, 8%, 25%);
  --gray7Color: hsl(202, 5%, 45%);
  --gray6Color: hsl(210, 6%, 65%);
  --gray5Color: hsl(210, 8%, 85%);
  --gray0Color: hsl(0, 0%, 100%);
  --gray1Color: hsl(0, 0%, 95%);
  --gray2Color: hsl(0, 0%, 91%);
  --gray3Color: hsl(0, 0%, 85%);
  --gray4Color: hsl(0, 0%, 80%);
  --plainWhiteColor: white;
  --plainBlackColor: black;
  --plainBlueColor: hsl(220, 27%, 44%);
  --plainCoralColor: coral;
  --plainLightBlueColor: rgb(7, 133, 218);
  --plainSlate50: hsl(210, 40%, 98%); /*rgb(248,250,252)*/
  --plainSlate100: hsl(210, 40%, 96.1%); /*rgb(241,245,249)*/
  --plainSlate200: hsl(214.3, 31.8%, 91.4%); /*rgb(226,232,240)*/
  --plainSlate300: hsl(212.7, 26.8%, 83.9%); /*rgb(203,213,225)*/
  --plainSlate400: hsl(215, 20.2%, 65.1%); /*rgb(148,163,184)*/
  --plainSlate500: hsl(215.4, 16.3%, 46.9%); /*rgb(100,116,139)*/
  --plainSlate700: hsl(215.3, 25%, 26.7%); /*rgb(51,65,85)*/
  --plainSlate900: hsl(222.2, 47.4%, 11.2%); /*rgb(15,23,42)*/
  --plainGray200: hsl(220, 13%, 91%); /*rgb(229,231,235)*/
  --plainGray500: hsl(220, 8.9%, 46.1%); /*rgb(107,114,128)*/
  --plainGray600: hsl(215, 13.8%, 34.1%); /*rgb(75,85,99)*/
  --plainGray700: hsl(216.9, 19.1%, 26.7%); /*rgb(55,65,81)*/
  --plainOrange50: hsl(33.3, 100%, 96.5%); /*rgb(255,247,237)*/
  --plainOrange100: hsl(34.3, 100%, 91.8%); /*rgb(255,237,213)*/
  --plainOrange200: hsl(32.1, 97.7%, 83.1%); /*rgb(254,215,170)*/
  --plainOrange300: hsl(30.7, 97.2%, 72.4%); /*rgb(253,186,116)*/
  --plainOrange400: hsl(27, 96%, 61%); /*rgb(251,146,60)*/
  --plainOrange500: hsl(24.6, 95%, 53.1%); /*rgb(249,115,22)*/
  --plainOrange600: hsl(20.5, 90.2%, 48.2%); /*rgb(234,88,12)*/
  --plainGreen300: hsl(141.7, 76.6%, 73.1%); /*rgb(134,239,172)*/
  --plainBlue800: hsl(220, 27%, 44%); /*rgb(30,64,175)*/
  --plainBlue900: hsl(206, 14%, 10%); /*rgb(30,58,138)*/
  --plainSky50: hsl(204, 100%, 97.1%); /*rgb(240,249,255)*/
  --plainSky100: hsl(204, 93.8%, 93.7%); /*rgb(224,242,254)*/
  --plainSky200: hsl(200.6, 94.4%, 86.1%); /*rgb(186,230,253)*/
  --plainSky300: hsl(199.4, 95.5%, 73.9%); /*rgb(125,211,252)*/
  --plainSky400: hsl(198.4, 93.2%, 59.6%); /*rgb(56,189,248)*/
  --plainSky500: hsl(198.6, 88.7%, 48.4%); /*rgb(14,165,233)*/
  --plainSky600: hsl(200.4, 98%, 39.4%); /*rgb(2,132,199)*/
  --plainSky700: hsl(201.3, 96.3%, 32.2%); /*rgb(3,105,161)*/
  --plainSky800: hsl(201, 90%, 27.5%); /*rgb(7,89,133)*/
  --plainSky900: hsl(202, 80.3%, 23.9%); /*rgb(12,74,110)*/
  --plainPurple200: hsl(268.6, 100%, 91.8%); /*rgb(233,213,255)*/
  --plainPurple600: hsl(271.5, 81.3%, 55.9%); /*rgb(147,51,234)*/
  --plainRose500: hsl(349.7, 89.2%, 60.2%); /*rgb(244,63,94)*/
  --plainLime600: hsl(84.8, 85.2%, 34.5%); /*rgb(101,163,13)*/
  --plainLime300: hsl(82, 84.5%, 67.1%); /*rgb(190,242,100)*/
  --plainLime100: hsl(79.6, 89.1%, 89.2%); /*rgb(236,252,203)*/
  --plainLime50: hsl(78.3, 92%, 95.1%); /*rgb(247,254,231)*/
  --plainYellow300: hsl(50.4, 97.8%, 63.5%); /*rgb(253,224,71)*/

  --bgColorPrimary: var(--plainSlate200);
  --bgColorPrimaryDark: var(--plainSlate500);
  --bgColorPrimaryDarker: var(--plainSlate700);
  --bgColorAccent: var(--plainSky600);
  --bgColorAccentDark: var(--plainBlue900);
  --bgColorAccentLight: var(--plainSky100);
  --bgColorAccentHighlight: var(--plainGreen300);
  --textColorPrimary: var(--plainSlate900);
  --textColorAccent: var(--plainSky800);
  --textColorHighlight: var(--plainRose500);
  --textColorWhite: var(--plainWhiteColor);
  --bodyColor: var(--plainGray700);
  --bgColorBrand: var(--plainYellow300);
  --rangeBulletColor: var(--plainSky500);
  --linkTextColor: var(--plainBlue900);
  --boxShadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  --bgColorFocus: var(--plainSlate400);
  --lighten: 20%;
  --darken: 15%;
  --bgColorBtnPrimary: var(--plainSky800);
  --bgColorBtnPrimaryLight: var(--plainSky600);
  --bgColorBtnPrimary-h: 201;
  --bgColorBtnPrimary-s: 90%;
  --bgColorBtnPrimary-l: 27.5%;
  --bgColorBtnPrimary-light: (
    var(--bgColorBtnPrimary-h),
    var(--bgColorBtnPrimary-s),
    calc(var(--bgColorBtnPrimary-l) + var(--lighten))
  );
  --bgColorBtnSecondary: var(--plainGray500);
  --textColorBtnPrimary: var(--plainWhiteColor);
  --bgColorInput: var(--plainSlate200);
  --bgColorInput-h: 128;
  --bgColorInput-s: 100%;
  --bgColorInput-l: 92%;
  --bgColorInput-light: (
    var(--bgColorInput-h),
    var(--bgColorInput-s),
    calc(var(--bgColorInput-l) + 8%)
  );
  --bgColorOutput1: #var(--plainOrange50);
  --bgColorOutput2: var(--plainWhiteColor);
  --bgColorTblHdr: var(--plainSky100);
  --bgColorEvenChild: var(--plainWhiteColor);
  --bgColorOddChild: var(--plainSlate50);
  --borderBottomColor: var(--plainGray700);
  --bgColorInputBlock: var(--plainSky50);
  --bgColorOutputBlock: var(--plainOrange100);
  --bgColorCard1: var(--plainWhiteColor);
  --bgColorCard2: var(--plainSlate50);
  --bgColorCard3: var(--plainSlate100);
  --bgColorCard4: var(--plainSlate200);
  --bgColorCard5: var(--plainSlate300);
  --bgLightGray: var(--plainGray500);
}
html {
  /* font-size: 10px; */

  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 60%;
  overflow-x: hidden;

  /* Does NOT work on Safari */
  /* scroll-behavior: smooth; */
}
.main-color {
  color: var(--textColorAccent);
}
.accent-color {
  color: var(--bgColorPrimary);
}
.main-color-background {
  background-color: var(--bgColorAccent);
}
body {
  color: var(--bodyColor);
  font-family: "Inter", sans-serif;
  font-family: "Roboto", sans-serif;
  /* background: linear-gradient(180deg, #bfe9ee 0%, #d2bdd8 100%) no-repeat; */
  width: 100vw;
  margin: 0 0;
  top: 0;
}
.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  color: var(--bodyColor); /* #333 */
  letter-spacing: -0.5px;
}
.heading-primary {
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}
.heading-primary .brand-name {
  font-size: 3.5rem;
  color: var(--bgColorBrand);
}
.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.subheading {
  /* display: block; */
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--textColorAccent);
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
  font-weight: 700;
}
.tertiary-text {
  font-size: 3rem;
  line-height: 1.2;
}

.main-text {
  font-size: 1.8rem;
  line-height: 1.8;
}

.small-text {
  font-size: 1.6rem;
  line-height: 1.6;
  /* font-style: italic; */
  /* font-weight: bold; */
}

.tiny-text {
  font-size: 1.4rem;
}
.italic-text {
  font-style: italic;
}
.bold-text {
  font-weight: 700;
}
.upper-text {
  text-transform: uppercase;
}
.link-text {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 0.4rem;
  color: var(--linkTextColor);
}
.centered-text {
  text-align: center;
}
.left-text {
  text-align: left;
}
.right-text {
  text-align: right;
}
.max-text-box {
  width: 75%;
  margin-right: 15%;
  margin-left: 15%;
}
.item-spacer-bottom {
  margin-bottom: 2rem;
}
/* .visible {
  display:inline-block;
}
.hidden {
  display: none;
} */
hr {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  height: 0.05%;
  background-color: var(--bgColorAccent);
}
.year,
.first-month {
  font-size: 1.6rem;
  font-weight: bold;
}
.box-minmax {
  /* margin-top: .5rem; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  color: var(--plainBlackColor);
}
.box-minmax span:first-child {
  margin-left: 0px;
}
#rs-range-line.rs-range-line {
  margin-top: 2rem;
  width: 100%;
  -webkit-appearance: none;
}
.rs-bullet.rs-range-line.rs-range-line::focus {
  border: none;
  outline: none;
}
.rs-range-line::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: var(--plainBlackColor);
  border-radius: 0px;
  border: 0px solid var(--plainBlackColor);
}
.rs-range-line::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: var(--plainBlackColor);
  border-radius: 0px;
  border: 0px solid var(--plainBlackColor);
}

.rs-range-line::-webkit-slider-thumb {
  box-shadow: none;
  border: 0px solid var(--plainWhiteColor);
  box-shadow: var(--boxShadow);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: var(--rangeBulletColor);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
.rs-range-line::-moz-range-thumb {
  box-shadow: none;
  border: 0px solid var(--plainWhiteColor);
  box-shadow: var(--boxShadow);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: var(--rangeBulletColor);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
.rs-range-line::-moz-focus-outer {
  border: 0;
}

.rs-bullet {
  /* visibility: hidden; */
  position: relative;
  transform-origin: center center;
  display: block;
  width: 180px;
  height: 100px;
  background-color: var(--bgColorCard3);
  border-radius: 25px;
  /* border-radius: 50%; */
  line-height: 1.6rem;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  border: 2px solid var(--bgColorFocus);
  /* margin-top: 20px; */
  margin-left: -38px;
  left: attr(value);
  color: var(--textColorPrimary);
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 1.2rem;
}
.rs-bullet::after {
  /* content: "months"; */
  display: block;
  font-size: 1.4rem;
  letter-spacing: 0.07rem;
  margin-top: -2px;
}

#button {
  display: none;
}

.container-fluid h3 {
  padding: 2% 5% 1%;
  font-weight: bold;
}
.page-content {
  width: 70%;
  /* justify-content: center; */
  margin: 0 auto;
}

.embed-slider {
  padding-left: 1.3rem;
  padding-right: 1.5rem;
  line-height: 2.5rem;
}

/* The next 2 classes allow a slider to be inflow with the text */
.range-slider__wrap {
  display: inline;
  align-items: center;
}
.slider-container {
  width: 35%;
  display: inline-block;
  align-items: center;
}

/* Style the tab content */
.tabcontent {
  padding: 0.2% 0%;
  border-top: none;
  margin-bottom: 5vh;
}
.tabcontent {
  animation: fadeEffect 1s; /*Fading effect takes 1 second */
}

.centered-tbl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -1vh;
  box-shadow: 0.5rem 1rem 0.8rem var(--boxShadow);
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  /* letter-spacing: 1px; */
  font-size: 1.6rem;
}

.amts {
  width: 90%;
}

.input-field {
  color: var(--textColorPrimary);
  font-weight: bold;
  background-color: var(--bgColorInput);
}

td,
th {
  padding: 1% 1%;
}

th {
  background-color: var(--bgColorTblHdr);
  color: var(--textColorPrimary);
  font-weight: bolder;
}
td {
  color: var(--textColorPrimary);
  font-weight: bold;
}

tr:nth-child(even) td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--borderBottomColor);
}

tr:nth-child(odd) td {
  border-bottom-color: var(--borderBottomColor);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

caption {
  padding: 2px;
}

.banner {
  /* width: 0lvw; */
  background-color: var(--bgColorTblHdr);
  color: var(--textColorPrimary);
}
.result {
  background-color: var(--bgColorAccent);
  color: var(--textColorPrimary);
  font-weight: 800;
}
.GoodResults {
  margin-top: 4px;
}

#goodcalc {
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0.3rem 2rem;
  margin-left: 3rem;
}

.input-block {
  grid-column: 1/2;
  grid-row: auto;
  margin-bottom: 1rem;
  padding: 1rem;
  /* width: 50%; */
  /* box-shadow: var(--boxShadow); */
  border: 1px;
  border-style: solid;
  border-color: var(--borderBottomColor);
  border-radius: 1%;
  background-color: var(--bgColorCard1);
  color: var(--textColorPrimary);
}
.qualify {
  grid-column: 2/3;
  grid-row: 1/2;
  align-items: center;
  position: sticky;
  top: 30%;
  background-color: var(--bgColorCard4);
}

.form {
  display: grid;
  /* width: 50%; */
  grid-template-columns: 2.5fr 1fr 2fr;
  grid-template-rows: auto auto auto;
  gap: 0.3rem 0.3rem;
  font-weight: bold;
}
.form-subheading {
  grid-column: 1/4;
  /* display: inline; */
  text-align: center;
  margin-top: 2rem;
}
/* Exceptions for long text */
.form-double {
  grid-column: 2/4;
  background-color: transparent;
}
.form__label--loan {
  grid-row: 2;
}
/* End exceptions */

.form-input {
  width: 100%;
  border: none;
  background-color: var(--bgColorInput);
  font-family: inherit;
  font-size: 1.8rem;
  line-height: 1.8;
  text-align: left;
  color: var(--textColorPrimary);
  padding: 0.3rem 0.3rem;
  border-radius: 1px;
  transition: all 0.3s;
  grid-column: 2/2;
}
.form-input:focus {
  outline: invert;
  background-color: var(--bgColorInput-light);
}
.form-out {
  width: 100%;
  border: none;
  background-color: var(--bgColorOutput2);
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.8;
  color: var(--textColorPrimary);
  padding: 0.3rem 0.3rem;
  border-radius: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.3s;
  border-bottom-color: var(--borderBottomColor);
  border-bottom-width: 1px;
}

.form-label {
  font-size: 1.6rem;
  text-align: right;
  padding-right: 1rem;
  align-self: center;
  /* font-weight: bold; */
  grid-column: 1/1;
  line-height: 1.8;
}
.form-suffix {
  font-size: 1.6rem;
  line-height: 1.8;
  text-align: left;
  /* font-weight: bold; */
  grid-column: 3/3;
  padding-top: 0.3rem;
  align-self: center;
}

#questions-container {
  grid-column: 1/4;
  display: none; /* set to grid later in js */
  /* width: 50%; */
  grid-template-columns: 1.5fr 1.5fr 0.7fr;
  grid-template-rows: auto auto auto;
  gap: 0.3rem 0.3rem;
  font-weight: bold;
  background-color: var(--bgColorInputBlock);
  border-radius: 2rem;
}
.q-grid {
  grid-column: 1/4;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 0.7fr;
  grid-template-rows: auto auto auto;
  gap: 0.3rem 0.3rem;
  font-weight: bold;
}
#next-btn {
  grid-column: 2;
  grid-row: 2;
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding: 10px 20px;
  background-color: var(--bgColorBtnPrimary);
  color: var(--textColorBtnPrimary);
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  width: 75%;
}
#next-btn:hover {
  outline: none;
  background-color: var(--bgColorBtnPrimary-light);
  color: var(--textColorBtnPrimary);
}
.qform-label {
  font-size: 1.6rem;
  text-align: right;
  padding-right: 1rem;
  align-self: center;
  /* font-weight: bold; */
  grid-column: 1/3;
  line-height: 1.8;
}

#csv {
  display: flex;
  justify-content: center;
}
.form-btn,
.calcAmort-btn {
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
  background-color: var(--bgColorBtnPrimary);
  color: var(--textColorBtnPrimary);
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  align-content: center;
  width: 75%;
}
.calcAmort-btn {
  display: flex;
  /* padding-top: 2vh; */
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}
.form-btn:hover {
  outline: none;
  background-color: var(--bgColorBtnPrimaryLight);
  color: var(--textColorBtnPrimary);
}
/* Pricing Section */

#pricing {
  padding: 2%;
}
.card-year1 {
  background-color: var(--bgColorCard1);
}

.card-year2 {
  background-color: var(--bgColorCard2);
}

.card-year3 {
  background-color: var(--bgColorCard3);
}
.card-year1,
.card-year2,
.card-year3 {
  box-shadow: 0.5rem 1.5rem 1.2rem rgba(0, 0, 0, 0.13);
  border-radius: 1rem;
}
.card-header {
  line-height: 1rem;
}
.card-body {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: auto auto;
  gap: 0.2rem 0.2rem;
  line-height: 1rem;
}
.card-body-msg {
  grid-column: 1/3;
}
.card-body-label {
  grid-column: 1/1;
  line-height: 0.8rem;
}
.card-data {
  width: 100%;
  border: none;
  line-height: 0.8rem;
  color: var(--textColorPrimary);
  padding: 0rem 0.3rem;
  border-radius: 1rem;
  transition: all 0.3s;
}
.card-body .price-text {
  grid-column: 1/3;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--textColorAccent);
  text-align: center;
  line-height: 2.5rem;
}
.price-text-label {
  grid-column: 1/3;
  font-weight: normal;
  color: var(--textColorAccent);
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding-top: 2vh;
}
#year3-msg {
  padding-top: 1.3rem;
  padding-bottom: 0.3rem;
  font-size: 2rem;
}

.pricing-column {
  padding: 1% 1%;
}
#monthly-tbl {
  margin-top: -4vh;
  margin-bottom: -3vh;
}

.tbl-header {
  text-align: center;
  margin-top: 3vh;
}
.text-padding-top {
  margin-top: 2vh;
}

.price-text {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--textColorAccent);
}

.footer-section {
  /* position: absolute; */
  /* margin-top: 10vh; */
  margin-bottom: 0;
  background: var(--bgLightGray);
  color: var(--plainWhiteColor);
}
/*  the footer image    */
.footer-2 {
  padding-top: 0vh;
  text-align: left;
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 5%;
  margin-right: 3%;
  line-height: 1.2;
  letter-spacing: 0.1rem;
}
.disclaimer {
  display: grid;
  margin-left: 5%;
  grid-template-columns: 0.5fr 4fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 0.2rem 0.1rem;
  line-height: 1.2;
  letter-spacing: 0.1rem;
}
.image-8 {
  width: 6rem;
  height: auto;
  grid-row: 2/3;
}
.text-8 {
  font-size: 1.2rem;
  padding-top: 2vh;
  text-align: left;
  grid-column: 2/3;
  grid-row: 2/3;
}
/* Following settings allow an inline slider */

.card2-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0.2rem 0.2rem;
  line-height: 1rem;
  padding-top: 1rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
  transition: all 0.3s;
}
.card2-title-left {
  grid-column: 1;
  grid-row: 1;
  text-align: left;
  font-size: 1rem;
  /* font-weight: bold; */
  letter-spacing: 0.2rem;
}
.card2-title-right {
  grid-column: 2;
  grid-row: 1;
  text-align: right;
}
.card2-header-line {
  grid-column: 1/3;
  grid-row: 2;
  background-color: var(--plainBlackColor);
  min-height: 1px;
}

.card2-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  gap: 0.2% 0.2% 0.2% 0.2%;
  line-height: 1vh;
}
.card2-body-label,
.card2-body-label-2 {
  font-size: 1.6rem;
  text-align: left;
  font-weight: normal;
  text-align: center;
  grid-row: 2/3;
  grid-column: auto;
  line-height: 0.5vh;
  padding-bottom: 1vh;
}
.card2-data,
.card2-body-data-2 {
  grid-row: 3/4;
  grid-column: auto;
  font-weight: bold;
  font-size: 1.6rem;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  border: none;
  font-family: inherit;
  text-align: center;
  color: var(--textColorPrimary);
  /* padding: 1vh 0.3vw; */
}
#savingsMsg {
  grid-row: 4/5;
  grid-column: 1 / span all;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.sc-labels {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.table-hdr-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr 1fr);
  grid-template-rows: repeat (2, auto);
  gap: repeat (8, 0.2%);
  line-height: 1rem;
  padding-top: 1rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
  transition: all 0.3s;
}
.table-hdr-grid-item-label {
  grid-column: auto;
  grid-row: 1;
  text-align: left;
  font-weight: bold;
  font-size: 1.2rem;
}
.table-hdr-grid-item-data {
  grid-column: auto;
  grid-row: 2;
  text-align: left;
  font-weight: bold;
  font-size: 1.6rem;
  padding-top: 1vh;
  /* padding-left: .3rem;
  padding-right: .3rem; */
  /* padding-bottom: 2em; */
}
.left-aligned-hdr {
  text-align: left;
  font-size: 1.6rem;
  font-weight: bold;
}
.loan-output {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  text-align: left;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
.chart-grid {
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  /* grid-template-columns: 
    minmax(70%, max-content) 
    repeat(autofill, 70%) repeat(autofill, 30%); */
  grid-template-rows: auto auto;
  gap: 0.2rem 0.2rem;
  padding-top: 1rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
  transition: all 0.3s;
}
.chart-grid-chart {
  grid-column: 1;
  display: grid;
  grid-row: auto;
  text-align: left;
  font-weight: bold;
  font-size: 0.8rem;
}
#lineChart {
  display: flex;
  height: 100%;
  width: 100%;
}
.chart-grid-second-column {
  grid-column: 2;
  grid-row: auto;
}

.toggle-button,
.toggle-slider-button {
  padding-top: 0.5rem;
  color: var(--plainBlueColor);
}
.toggle-slider-button {
  padding-bottom: 2vh;
  text-align: center;
}
.chart-grid-table {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  grid-template-rows: auto auto auto;
  gap: 0.2em 0.2em 0.2rem;
  padding-top: 1rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
  transition: all 0.3s;
}
.chart-table-line {
  grid-column: 1/4;
  grid-row: auto;
  background-color: var(--plainBlackColor);
  min-height: 1px;
}
.line-heading {
  grid-column: 1/4;
  grid-row: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}
.line-color {
  grid-column: 1;
  grid-row: auto;
  border-radius: 2vw;
  height: 1vw;
  width: 1vw;
  box-shadow: none;
  border: 0px solid var(--plainWhiteColor);
  box-shadow: var(--boxShadow);
}
.line-label {
  grid-column: 2;
  grid-row: auto;
  font-weight: bold;
  font-size: 1.6rem;
}
.line-data {
  grid-column: 3;
  grid-row: auto;
  font-weight: bold;
  font-size: 1.6rem;
}
.optional-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 2vh 0.5vw;
  line-height: 1.5vh;
}
.optional-body-msg {
  grid-column: 1/3;
  font-size: 1.6rem;
  padding: 0.5rem 0.5rem;
  line-height: 3.5vh;
}
.optional-label {
  text-align: left;
  font-weight: normal;
  grid-column: auto;
  grid-row: auto;
  line-height: 1.2vh;
  font-size: 1.6rem;
  padding-bottom: 0.5vh;
}
.optional-data {
  grid-column: auto;
  grid-row: auto;
  font-family: inherit;
  font-size: 1.6rem;
  /* font-weight: bold; */
  text-align: left;
  line-height: 4vh;
  color: var(--textColorPrimary);
  padding: 0vh 0.3vw;
  /* border: 1px solid #000000;*/
  border-radius: 1rem;
  transition: all 0.3s;
}
.home-grid {
  display: flex;
  gap: 2%;
  margin-top: 10vh;
}
.tools-section {
  /* grid-column: 1;
  grid-row: 1; */
  order: -1;
  flex: 1;
  /* text-align: left;
  font-weight: bold; */
  /* font-size: 0.8vw; */
  border-top: 1rem solid var(--bgColorAccent);
  border-bottom: 1rem solid var(--bgColorAccent);
  background-color: var(--bgColorPrimary);
  gap: 0 0 1rem 1rem;
}
.home-grid hr {
  /* grid-column: 2;
  grid-row: auto; */
  order: 0;
  height: 100vh;
  width: 0.2vw;
  /* margin-left: 26%; */
  /* border-width: .3; */
  background-color: var(--bgColorAccent);
  display: block;
  transform: rotate(90);
}

.content-section {
  /* grid-column: 3;
  grid-row: 1; */
  order: 1;
  flex: 3;
  /* text-align: left; */
  /* font-weight: bold; */
  /* font-size: 0.8vw; */
}

.tools-list-hdr {
  list-style: none;
  margin-top: 1rem;
  /* font-size: 1rem;
  font-weight: bolder; */
  text-transform: uppercase;
  /* padding: 1em 0 1em 0; */
  text-align: center;
  letter-spacing: 0.2rem;
  color: green;
}

.tools-list {
  list-style: none;
  /* font-weight: bold; */
}

.link:link {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 0.1rem;
  text-align: center;
  cursor: pointer;
}

.link:visited {
  color: var(--plainLightBlueColor);
}

.link:hover {
  color: var(--plainCoralColor);
}

/*TOOLTIP STYLES */
input[type="text"]:hover::before,
span[type="text"]:hover::before,
.form-label:hover::before,
.btn:hover::before {
  content: attr(title);
  position: absolute;
  padding: 4px 8px;
  background-color: var(--bgColorPrimary);
  color: var(--plainWhiteColor);
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.3s ease;
}
input[type="number"] {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

input[type="text"]:hover::after,
span[type="text"]:hover::after,
.form-label:hover::after,
.btn:hover::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  font-weight: bold;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--bgColorPrimary) transparent transparent transparent;
  bottom: 98%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

input[type="text"]:hover::before,
input[type="text"]:hover::after,
span[type="text"]:hover::before,
span[type="text"]:hover::after,
.form-label:hover::before,
.form-label:hover::after,
.btn:hover::before,
.btn:hover::after {
  opacity: 1;
}

.accordion-button.accordion-button {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--bgColorBtnPrimary);
  text-transform: uppercase;
  text-align: center;
}
.accordion-item.accordion-item {
  margin-bottom: 4rem;
  font-weight: 800;
  border: none;
}
/* //////////////////////////////////////////////////// */
@media screen and (max-width: 900px) {
  html {
    /* font-size: 10px; */

    /* 10px / 16px = 0.625 = 62.5% */
    /* Percentage of user's browser font-size setting */
    font-size: 60%;
  }
  legend {
    font-size: 2.5rem;
  }
  .year,
  .first-month {
    font-size: 1.6rem;
  }
  /* .banner {
    font-size: 2rem;
  } */
  .page-content {
    width: 90%;
  }

  .amts {
    width: 90%;
    font-size: 1.6rem;
  }

  table {
    width: 100%;
    font-size: 1.6rem;
    margin: 0 auto;
  }
  .input-block {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  html {
    /* font-size: 10px; */

    /* 10px / 16px = 0.625 = 62.5% */
    /* Percentage of user's browser font-size setting */
    font-size: 55%;
  }
  .centered-tbl {
    width: 100%;
  }
  .reduction-tbl,
  .red-tbl-body > .main-text,
  .GoodResults {
    font-size: 1.4rem;
  }

  /* .year,
    .first-month {
      font-size: 3vw;
    }
   legend {
      font-size: 4vw;
    } 
  .banner {
    font-size: 2vw;
  } */
  .page-content {
    width: 100%;
  }

  /* .amts {
    width: 3em;
    font-size: 1em;
  } */

  table {
    width: 100%;
    font-size: 1.6rem;
  }
  /* #year3-msg {
    padding-top: 1.3rem;
    padding-bottom: 0.3rem;
    font-size: 1.5rem;
  } */

  #monthly-tbl {
    margin-top: -1vh;
  }
  .input-block {
    width: 100%;
  }
  .card2-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 0.2% 0.2%;
    line-height: 1.6rem;
  }
  .card2-body-label {
    font-size: 1.6rem;
    padding-bottom: 1vh;
    text-align: left;
    font-weight: normal;
    text-align: center;
    grid-row: 1/2;
    grid-column: auto;
    line-height: 1.6rem;
  }
  .card2-data {
    grid-row: 2/3;
    grid-column: auto;
    font-weight: bold;
    font-size: 1.6rem;
    padding-top: 0.5vh;
    padding-bottom: 2vh;
  }
  .card2-body-label-2 {
    font-size: 1.6rem;
    padding-bottom: 1vh;
    text-align: left;
    font-weight: normal;
    text-align: center;
    grid-row: 4/5;
    grid-column: auto;
    line-height: 1.6rem;
  }
  .card2-body-data-2 {
    grid-row: 5/6;
    grid-column: auto;
    font-weight: bold;
    font-size: 1.6rem;
    padding-top: 0.5vh;
    /* background-color: rgba(119, 185, 225, 0.4); */
    font-family: inherit;
    text-align: center;
    /* color: #333; */
    padding: 0.5vh 0.3vw;
  }
  #savingsMsg {
    grid-row: 6/7;
    grid-column: 1 / span all;
    display: none;
  }
  /* .card2-data {
    grid-row: 3/4;
    font-size: 1.6rem;
    padding-top: 0.5vh;
  } */
  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-columns: 
      minmax(70%, max-content) 
      repeat(autofill, 70%) repeat(autofill, 30%); */
    grid-template-rows: auto;
    gap: 0.2rem 0.2rem;
    padding-top: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
    transition: all 0.3s;
  }
  .chart-grid-chart {
    grid-column: 1;
    display: grid;
    grid-row: auto;
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
  }
  #lineChart {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 1rem;
  }
  .chart-grid-second-column {
    grid-column: 1;
    grid-row: auto;
  }
  .line-heading {
    font-size: 1.6rem;
  }
  .line-color {
    border-radius: 4vw;
    height: 2vw;
    width: 2vw;
    box-shadow: none;
    /* border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25); */
    padding-top: 2vh;
  }
  .line-label {
    font-size: 1.6rem;
  }
  .line-data {
    font-size: 1.6rem;
  }
  .optional-body-msg {
    grid-column: 1/3;
    font-size: 1.8rem;
    padding: 0.5vh 1vw;
    line-height: 2vh;
  }
  .optional-label {
    text-align: left;
    grid-column: auto;
    grid-row: auto;
    line-height: 1.2vh;
    font-size: 1.8rem;
    padding-bottom: 1vh;
  }
  .optional-data {
    grid-column: auto;
    grid-row: auto;
    /* border: none; */

    font-family: inherit;
    font-size: 1.8rem;
    text-align: left;
    line-height: 5vh;
    /* color: #333; */
    padding: 0vh 1vw;
    /* background-color: rgba(119, 185, 225, 0.4);
    border: 1px solid #000000;
    border-radius: 0.7%;
    transition: all 0.3s; */
  }
  .home-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0.2em 0.2rem;
    padding-top: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
    transition: all 0.3s;
  }

  .tools-section {
    grid-column: 1;
    grid-row: 2;
    /* text-align: left; */
    /* font-weight: bold;
    font-size: 0.8vw; */
    /* border-top: 1em solid gold;
    border-bottom: 1em solid gold; */
    /* background-color: #fffaf5; */
    gap: 0 0 1em 1em;
    padding-right: 1rem;
  }

  .content-section {
    grid-column: 1;
    grid-row: 1;
    /* text-align: left;
    font-weight: bold;
    font-size: 0.8vw; */
  }

  .tools-list-hdr {
    list-style: none;
    margin-top: 1rem;
    /* font-size: 1rem;
    font-weight: bolder; */
    text-transform: uppercase;
    /* padding: 1em 0 1em 0; */
    text-align: center;
    letter-spacing: 0.2rem;
    color: green;
  }
  /* .tools-list {
    list-style: none;
    font-weight: bold;
  } */

  .home-grid hr {
    grid-column: 2;
    grid-row: auto;
    height: 0;
    width: 0;
    /* margin-left: 26%; */
    /* border-width: .3; */
    /* background-color: gold;
   display: block;
   transform: rotate(90); */
  }

  .link:link {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 0.1rem;
    text-align: center;
  }

  .link-text {
    font-size: 1.8rem;
    margin-bottom: 0.4rem;
    color: darkslateblue;
  }
  .input-block {
    grid-column: 1/3;
    grid-row: auto;
    margin-bottom: 3rem;
    padding: 2rem;
    box-shadow: var(--boxShadow);
    border-radius: 1rem;
    /* background-color: #fffaf5; */
  }
  .qualify {
    grid-column: 1/3;
    grid-row: auto;
    align-items: center;
    position: sticky;
    top: 30%;
    /* background-color: #dbe6d5; */
  }
  .max-text-box {
    width: 100%;
    margin-right: 5%;
    margin-left: 0;
  }
  input[type="text"]:hover::before,
  span[type="text"]:hover::before,
  .form-label:hover::before,
  .btn:hover::before {
    content: "";
  }
  /* Go from zero to full opacity */
  @keyframes fadeEffect {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
